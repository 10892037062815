<template>
  <!--begin::details View-->
  <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
    <!--begin::Card header-->
    <div class="card-header cursor-pointer">
      <!--begin::Card title-->
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">จัดการ</h3>
      </div>
      <!--end::Card title-->
    </div>
    <!--begin::Card header-->
    <!--begin::Card body-->
    <div class="card-body p-9">
      <!--begin::Row-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">สถานะ</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <span class="fw-bolder fs-6 text-dark">{{ taxs.status }}</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->
      <Form
        v-if="taxs.sub_title === 'สอบถามยอดและวันครบกำหนดชำระ'"
        @submit="onSubmitTicket"
        :validation-schema="ticket"
        id="kt_account_profile_details_form"
        class="form"
        novalidate="novalidate"
      >
        <BaseInput name="id" type="hidden" v-model="id" />
        <BaseInput
          label="ยอดจ่าย"
          name="amount_paid"
          type="number"
          v-model="amount_paid"
          @change="total_s"
        />
        <BaseInput
          label="ยอดปรับ"
          name="fines"
          type="number"
          v-model="fines"
          @change="total_s"
        />

        <div class="row mb-6">
          <!--begin::Label-->
          <label class="col-lg-4 col-form-label fw-bold fs-6">ยอดรวม</label>
          <div class="col-lg-8 fv-row ml-5">
            <span class="tt">{{ total_t }} บาท </span>
          </div>
        </div>

        <!--begin::Input group-->
        <div class="row mb-6">
          <!--begin::Label-->
          <label class="col-lg-4 col-form-label required fw-bold fs-6"
            >วันที่ครบกำหนดชำระ</label
          >
          <!--end::Label-->

          <!--begin::Col-->
          <div class="col-lg-8 fv-row">
            <Datepicker
              locale="th"
              placeholder="เลือกวัน"
              :enableTimePicker="false"
              :format-locale="th"
              cancelText="ยกเลิก"
              selectText="เลือก"
              v-model="date"
              :minDate="new Date()"
            >
            </Datepicker>
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="due_date" />
              </div>
            </div>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Input group-->
        <BaseInput
          required="required"
          label="รายละเอียด"
          as="textarea"
          name="detail_return"
        />
        <BaseInput
          label="link สำหรับดาวน์โหลดแบบฟอร์มภาษี"
          name="link_dowload"
          type="text"
        />
        <!--begin::Row-->
        <div class="row mb-7">
          <!--begin::Label-->
          <label class="col-lg-4 fw-bold text-muted"></label>
          <!--end::Label-->
          <!--begin::Col-->
          <div class="col-lg-8">
            <button
              type="submit"
              id="kt_account_profile_details_submit"
              ref="submitButton"
              class="btn btn-primary"
            >
              <span class="indicator-label"> ยืนยัน </span>
              <span class="indicator-progress">
                กรุณารอสักครู่ ...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->
      </Form>
      <Form
        v-else
        @submit="onSubmitTicket"
        :validation-schema="ticket"
        id="kt_account_profile_details_form"
        class="form"
        novalidate="novalidate"
      >
        <BaseInput name="id" type="hidden" v-model="id" />
        <BaseInput
          required="required"
          label="รายละเอียดการแจ้งกลับ"
          as="textarea"
          name="detail_return"
        />
        <BaseInput
          label="link สำหรับดาวน์โหลดแบบฟอร์มภาษี"
          name="link_dowload"
          type="text"
        />
        <!--begin::Row-->
        <div class="row mb-7">
          <!--begin::Label-->
          <label class="col-lg-4 fw-bold text-muted"></label>
          <!--end::Label-->
          <!--begin::Col-->
          <div class="col-lg-8">
            <button
              type="submit"
              id="kt_account_profile_details_submit"
              ref="submitButton"
              class="btn btn-primary"
            >
              <span class="indicator-label"> ยืนยัน </span>
              <span class="indicator-progress">
                กรุณารอสักครู่ ...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->
      </Form>
    </div>
  </div>
</template>

<script>
import BaseInput from "@/components/modals/BaseInput.vue";
import { defineComponent, computed, ref } from "vue";
import moment from "moment";
import useSweetalert from "@/core/helpers/sweetalert2";
import useTaxs from "@/core/services/api/tax/taxs";
import { Form } from "vee-validate";
import * as Yup from "yup";
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";
import { th } from "date-fns/locale";
export default defineComponent({
  name: "tax-show-manage",
  props: ["taxs"],
  components: {
    Form,
    BaseInput,
    Datepicker,
  },
  setup(props, { emit }) {
    const { Sconfirm, SToast } = useSweetalert();
    const { FinishAticket } = useTaxs();
    const ticket = Yup.object().shape({
      detail_return: Yup.string()
        .required("กรุณากรอก รายละเอียด")
        .label("รายละเอียด"),
    });
    const date = ref();

    const amount_paid = ref(0);
    const fines = ref(0);
    const total = ref(0);
    const id = computed(() => {
      return props.taxs.id;
    });

    const total_t = computed(() => {
      return total.value.toFixed(2);
    });

    function total_s() {
      total.value = Number(amount_paid.value) + Number(fines.value);
    }

    const onSubmitTicket = async (values) => {
      values.due_date = date.value;
      Sconfirm("ยืนยัน การปิดงาน", "question").then(async (result) => {
        if (result.isConfirmed) {
          emit("loader");
          await FinishAticket(values)
            .then(() => {
              SToast("success", "การปิดงาน ใบงาน สำเร็จ");
              emit("getAticket");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                SToast("error", errors.message);
              }
            });
        }
      });
    };

    function dateThai(date) {
      var y = moment(date).format("yyy");
      var year = parseInt(y) + 543;
      var march = moment(date).locale("th");

      return march.format("D MMM" + year + ", LT");
    }

    return {
      dateThai,
      ticket,
      onSubmitTicket,
      id,
      amount_paid,
      fines,
      total_t,
      total_s,
      th,
      date,
    };
  },
});
</script>

<style scoped>
.tt {
  margin-left: 20px;
  font-weight: bold;
}
.bt-delete {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 10px !important;
  border-radius: 0px;
}
.image-logo {
  width: 250px;
  height: 250px;
  box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 2%);
  border: 1px solid #eff2f5;
}
.btn-new-icon {
  width: 250px;
  height: 250px;
  background-color: #f5f8fa;
}
.icon-ima {
  width: 30px;
  margin-left: 5px;
}
.btn-outline-secondary {
  border: 1px solid var(--color) !important;
}
.images {
  width: 100%;
}
.xx {
  position: absolute;
  background-color: #bd313e;
  color: white;
  right: 12px;
  padding: 6px;
}
.addimage {
  border: 1px solid var(--color) !important;
}
</style>