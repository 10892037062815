<template>
  <!--begin::Navbar-->
  <div class="card mb-5 mb-xl-10">
    <div class="card-body pt-9 pb-0">
      <!--begin::Details-->
      <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
        <!--begin: Pic-->
        <div class="me-7 mb-4">
          <div
            class="
              symbol symbol-100px symbol-lg-160px symbol-fixed
              position-relative
            "
          >
            <img
              v-if="profile_line.pictureUrl"
              :src="profile_line.pictureUrl"
              alt="image"
            />
            <img v-else src="media/avatars/blank.png" alt="image" />

            <div
              class="
                position-absolute
                translate-middle
                bottom-0
                start-100
                mb-6
                bg-success
                rounded-circle
                border border-4 border-white
                h-20px
                w-20px
              "
            ></div>
          </div>
        </div>
        <!--end::Pic-->

        <!--begin::Info-->
        <div class="flex-grow-1">
          <!--begin::Title-->
          <div
            class="
              d-flex
              justify-content-between
              align-items-start
              flex-wrap
              mb-2
            "
          >
            <!--begin::User-->
            <div class="d-flex flex-column">
              <!--begin::Name-->
              <div
                class="d-flex align-items-center mb-2"
                v-if="taxs.user.firstname"
              >
                <a
                  href="#"
                  class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1"
                  >{{ taxs.user.firstname }} {{ taxs.user.lastname }}</a
                >
                <a href="#">
                  <!--begin::Svg Icon | path: icons/duotone/Design/Verified.svg-->
                  <span class="svg-icon svg-icon-1 svg-icon-primary">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <path
                        d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z"
                        fill="#00A3FF"
                      />
                      <path
                        class="permanent"
                        d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                </a>
              </div>
              <!--end::Name-->
              <!--begin::Info-->
              <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                <a
                  href="#"
                  class="
                    d-flex
                    align-items-center
                    text-gray-400 text-hover-primary
                    me-5
                    mb-2
                  "
                >
                  <!--begin::Svg Icon | path: icons/duotone/General/User.svg-->
                  <span class="svg-icon svg-icon-4 me-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <path
                          d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                          opacity="0.3"
                        />
                        <path
                          d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                        />
                      </g>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->{{ taxs.user.gender }}
                </a>
                <a
                  href="#"
                  class="
                    d-flex
                    align-items-center
                    text-gray-400 text-hover-primary
                    me-5
                    mb-2
                  "
                >
                  <!--begin::Svg Icon | path: icons/duotone/Map/Marker1.svg-->
                  <span class="svg-icon svg-icon-4 me-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24" />
                        <path
                          d="M5,10.5 C5,6 8,3 12.5,3 C17,3 20,6.75 20,10.5 C20,12.8325623 17.8236613,16.03566 13.470984,20.1092932 C12.9154018,20.6292577 12.0585054,20.6508331 11.4774555,20.1594925 C7.15915182,16.5078313 5,13.2880005 5,10.5 Z M12.5,12 C13.8807119,12 15,10.8807119 15,9.5 C15,8.11928813 13.8807119,7 12.5,7 C11.1192881,7 10,8.11928813 10,9.5 C10,10.8807119 11.1192881,12 12.5,12 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                        />
                      </g>
                    </svg>
                  </span>
                  <!--end::Svg Icon-->{{ taxs.user.district }}
                  {{ taxs.user.amphoe }} {{ taxs.user.province }}
                </a>
                <a
                  href="#"
                  class="
                    d-flex
                    align-items-center
                    text-gray-400 text-hover-primary
                    mb-2
                  "
                >
                  <!--begin::Svg Icon | path: icons/duotone/Communication/Mail-at.svg-->
                  <span class="svg-icon svg-icon-4 me-1">
                    <i class="bi bi-telephone-fill" style="width: 10px"></i>
                  </span>
                  <!--end::Svg Icon-->{{ taxs.user.tel }}</a
                >
              </div>
              <!--end::Info-->
            </div>
            <!--end::User-->
            <!--begin::Actions-->
            <div class="d-flex my-4" v-if="can('appeal-edit', 'all')">
              <!--begin::Action-->

              <button
                v-if="taxs.status === 'รอตรวจสอบ'"
                type="submit"
                class="btn btn-primary align-self-center"
                @click="onSubmitAticket(taxs.id)"
              >
                ดำเนินการ
              </button>

              <!--end::Action-->
            </div>

            <!--end::Actions-->
          </div>
          <!--end::Title-->
          <!--begin::Stats-->
          <div class="d-flex flex-wrap flex-stack">
            <!--begin::Wrapper-->
            <div class="d-flex flex-column flex-grow-1 pe-8">
              <!--begin::Stats-->
              <div class="d-flex flex-wrap">
                <!--begin::Stat-->
                <div
                  class="
                    border border-gray-300 border-dashed
                    rounded
                    min-w-125px
                    py-3
                    px-4
                    me-6
                    mb-3
                  "
                >
                  <!--begin::Number-->
                  <div class="d-flex align-items-center">
                    <!--begin::Svg Icon | path: icons/duotone/Navigation/Arrow-up.svg-->
                    <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                    </span>
                    <!--end::Svg Icon-->
                    เวลาแจ้ง
                  </div>
                  <!--end::Number-->
                  <!--begin::Label-->
                  <div class="fw-bold fs-6 text-gray-400">
                    {{ dateThai(taxs.created_at) }}
                  </div>
                  <!--end::Label-->
                </div>
                <!--end::Stat-->

                <!--begin::Stat-->
                <div
                  class="
                    border border-gray-300 border-dashed
                    rounded
                    min-w-125px
                    py-3
                    px-4
                    me-6
                    mb-3
                  "
                >
                  <!--begin::Number-->
                  <div class="d-flex align-items-center">
                    <!--begin::Svg Icon | path: icons/duotone/Navigation/Arrow-up.svg-->
                    <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                    </span>
                    <!--end::Svg Icon-->
                    เวลาดำเนินการ
                  </div>
                  <!--end::Number-->
                  <!--begin::Label-->
                  <div
                    v-if="timeline_processing"
                    class="fw-bold fs-6 text-gray-400"
                    style="text-align: center"
                  >
                    {{ timeline_processing }}
                  </div>

                  <div
                    v-else
                    class="fw-bold fs-6 text-gray-400"
                    style="text-align: center"
                  >
                    -
                  </div>

                  <!--end::Label-->
                </div>
                <!--end::Stat-->

                <!--begin::Stat-->
                <div
                  class="
                    border border-gray-300 border-dashed
                    rounded
                    min-w-125px
                    py-3
                    px-4
                    me-6
                    mb-3
                  "
                >
                  <!--begin::Number-->
                  <div class="d-flex align-items-center">
                    <!--begin::Svg Icon | path: icons/duotone/Navigation/Arrow-up.svg-->
                    <span class="svg-icon svg-icon-3 svg-icon-success me-2">
                    </span>
                    <!--end::Svg Icon-->
                    เวลาปิดงาน
                  </div>
                  <!--end::Number-->
                  <!--begin::Label-->
                  <div
                    class="fw-bold fs-6 text-gray-400"
                    style="text-align: center"
                    v-if="timeline_finish"
                  >
                    {{ timeline_finish }}
                  </div>
                  <div
                    class="fw-bold fs-6 text-gray-400"
                    style="text-align: center"
                    v-else
                  >
                    -
                  </div>
                  <!--end::Label-->
                </div>
                <!--end::Stat-->
              </div>
              <!--end::Stats-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Stats-->
        </div>
        <!--end::Info-->
      </div>
      <!--end::Details-->

      <!--begin::Navs-->
    </div>
  </div>
  <!--end::Navbar-->
</template>

<script>
import moment from "moment";
import { defineComponent, computed } from "vue";
import { useAbility } from "@casl/vue";
import useSweetalert from "@/core/helpers/sweetalert2";
import useTaxs from "@/core/services/api/tax/taxs";
export default defineComponent({
  name: "tax-show-navbar",
  props: ["taxs", "profile_line"],
  setup(props, { emit }) {
    const { can } = useAbility();
    const { Sconfirm, SToast } = useSweetalert();
    const { getAticket } = useTaxs();
    function dateThai(v) {
      var y = moment(v).format("yyy");
      var year = parseInt(y) + 543;
      var march = moment(v).locale("th");

      return march.format("D MMM" + year + ", LT");
    }

    const timeline_receive = computed(() => {
      const date = props.taxs.timeline.filter(
        (item) => item.status === "รับคำร้อง"
      );
      if (date.length > 0) {
        return dateThai(date[0].created_at);
      }
      return "";
    });

    const timeline_processing = computed(() => {
      const date = props.taxs.timeline.filter(
        (item) => item.status === "ดำเนินการ"
      );
      if (date.length > 0) {
        return dateThai(date[0].created_at);
      }
      return "";
    });

    const timeline_finish = computed(() => {
      const date = props.taxs.timeline.filter(
        (item) => item.status === "เสร็จสิ้น"
      );
      if (date.length > 0) {
        return dateThai(date[0].created_at);
      }
      return "";
    });

    const onSubmitAticket = (id) => {
      Sconfirm("ยืนยัน การดำเนินการ", "question").then(async (result) => {
        if (result.isConfirmed) {
          emit("loader");
          let data = {
            id: id,
          };
          await getAticket(data)
            .then(() => {
              SToast("success", "ดำเนินการ ใบงาน สำเร็จ");
              emit("getAticket");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                SToast("error", errors.message);
              }
            });
        }
      });
    };

    return {
      can,
      dateThai,
      timeline_receive,
      timeline_processing,
      timeline_finish,
      onSubmitAticket,
    };
  },
});
</script>

<style></style>
