<template>
  <div class="vld-parent">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      :color="color"
    ></loading>
    <!--begin::Post-->
    <div class="post d-flex flex-column-fluid" id="kt_post">
      <!--begin::Container-->
      <div id="kt_content_container" class="container">
        <Navbar
          v-if="taxs.user"
          :profile_line="profile_line"
          :taxs="taxs"
          @getAticket="getApp"
          @loader="loader"
        ></Navbar>
        <Detail v-if="taxs.user" :taxs="taxs"></Detail>
        <Timeline v-if="taxs.user && taxs.status !== 'รอตรวจสอบ'" :taxs="taxs"></Timeline>
        <Menage
          v-if="taxs.user && taxs.status !== 'เสร็จสิ้น' && taxs.status !== 'รอตรวจสอบ'"
          :taxs="taxs"
          @getAticket="getApp"
          @loader="loader"
        ></Menage>
        <Report
          v-if="taxs.status === 'เสร็จสิ้น'"
          :taxs="taxs"
        ></Report>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/tax/Navbar";
import Detail from "@/components/tax/Detail";
import Timeline from "@/components/tax/Timeline";
import Menage from "@/components/tax/Menage";
import Report from "@/components/tax/Report";
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import { checkPage } from "@/core/helpers/checkpage";
import useTaxs from "@/core/services/api/tax/taxs";
import useSweetalert from "@/core/helpers/sweetalert2";
export default defineComponent({
  name: "tax-show",
  components: {
    Navbar,
    Detail,
    Timeline,
    Menage,
    Report
  },
  setup() {
    const { SToast, SconfirmNc } = useSweetalert();
    const route = useRoute();
    const router = useRouter();
    const id = ref(route.params.id);
    const { getTax } = useTaxs();
    const taxs = ref([]);
    const profile_line = ref([]);
    let isLoading = ref(true);
    let color = ref(process.env.VUE_APP_COLOR);

    onMounted(() => {
      checkPage("tax-list");
      setCurrentPageBreadcrumbs("ใบงานที่ " + id.value, ["ใบงานสอบถามภาษี"]);
      getApp();
    });
    const loader = () =>{
      isLoading.value = true;
    }

    async function getApp() {
      isLoading.value = true;
      await getTax(id.value)
        .then((res) => {
          if (res.data.status === "Not Ticket") {
            SToast("error", "ไม่พบ ใบงานนี้");
            router.push({ name: "apps.taxs.taxs.wait" });
          } else if (res.data.status === "Not Auth") {
            SToast("error", "ไม่มีสิทธิ์เข้าถึง ใบงานนี้");
            router.push({ name: "apps.taxs.taxs.wait" });
          }

          taxs.value = res.data.data;
          profile_line.value = res.data.profile_line;
          isLoading.value = false;
        })
        .catch(() => {
          SconfirmNc(
            "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
            "error",
            "ลองอีกครั้ง !"
          );
          isLoading.value = false;
        });
    }

    return { id, isLoading, color, taxs, profile_line, getApp ,loader };
  },
});
</script>
<style scoped></style>
