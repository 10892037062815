<template>
  <!--begin::details View-->
  <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
    <!--begin::Card header-->
    <div class="card-header cursor-pointer">
      <!--begin::Card title-->
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">รายละเอียด</h3>
      </div>
      <!--end::Card title-->
    </div>
    <!--begin::Card header-->
    <!--begin::Card body-->
    <div class="card-body p-9">
      <!--begin::Row-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">หัวข้อ</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <span class="fw-bolder fs-6 text-dark">{{ taxs.title }}</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->
      <!--begin::Input group-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">เรื่องที่สอบถาม</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8 fv-row">
          <span class="fw-bold fs-6">{{ taxs.sub_title }}</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Input group-->
      <!--begin::Input group-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">รายละเอียด</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8 fv-row">
          <span class="fw-bold fs-6">{{ taxs.detail }}</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Input group-->
      <!--begin::Input group-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted"
          >เบอร์โทรศัพท์
          <i
            class="fas fa-exclamation-circle ms-1 fs-7"
            data-bs-toggle="tooltip"
            title="เบอร์โทรศัพท์ติดต่อผู้แจ้ง"
          ></i
        ></label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8 d-flex align-items-center">
          <span class="fw-bold fs-6">{{ taxs.user.tel }}</span>
          <span class="badge badge-success">Verified</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Input group-->
      <!--begin::Input group-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">วันที่แจ้ง</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <span class="fw-bold fs-6">{{ dateThai(taxs.created_at) }}</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">สถานะ</label>
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <span class="fw-bold fs-6">{{ taxs.status }}</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="row mb-7" v-if="taxs.link_dowload">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted"
          >link สำหรับดาวน์โหลดแบบฟอร์มภาษี</label
        >
        <!--end::Label-->
        <!--begin::Col-->
        <div class="col-lg-8">
          <button
            type="button"
            @click="dowload(taxs.link_dowload)"
            class="btn btn-success"
          >
            ดาวน์โหลด
          </button>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Input group-->
    </div>
    <!--end::Card body-->
  </div>
  <!--end::details View-->
</template>

<script>
import moment from "moment";
import { defineComponent } from "vue";
export default defineComponent({
  name: "taxs-show-detail",
  props: ["taxs"],
  setup() {
    function dateThai(date) {
      var y = moment(date).format("yyy");
      var year = parseInt(y) + 543;
      var march = moment(date).locale("th");

      return march.format("D MMM" + year + ", LT");
    }

    function dowload(link) {
      window.open(link);
    }

    return {
      dateThai,
      dowload,
    };
  },
});
</script>

<style scoped></style>
